import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import {Link} from "gatsby";
import especializamos from "../../../static/video/especializamos.mp4";
import transporte from "../../../static/images/lca/transporte11.jpg";
import telecomunicaciones from "../../../static/images/lca/telecomunicaciones11.jpg";
import social from "../../../static/images/lca/social.jpg";
import finanzas from "../../../static/images/lca/finanzas.jpg";
import inmobiliario from "../../../static/images/lca/inmobiliario.jpg";

const SectoresPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Sectores"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">
                                    Expertise and in-depth knowledge in the following </h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">SECTORS
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 sm-margin-30px-bottom">
                                <img className="" src={"/images/lca/interior/infra.jpg"} alt={''}/>
                            </div>
                            <div className="col-12 col-lg-5 col-md-6 offset-lg-1">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    Strategic Expertise</h5>
                                <p className="w-90">Our team is assembled by finance professionals with decades of
                                    global financial experience.
                                </p>
                                <p className="w-90">Since our inception, LCA has specialized primarily in the
                                    infrastructure, energy, public finance, and real estate sectors in the region.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}

                {/* start section */}
                <section className="wow animate__fadeIn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 sm-margin-30px-bottom">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray mb-0">
                                    Latin America is in great need of quality basic infrastructure.</h5>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 col-md-7">
                                <div className="row row-cols-1 row-cols-sm-2">
                                    {/* start counter item */}
                                    <div
                                        className="col counter-style-01 last-paragraph-no-margin xs-margin-30px-bottom">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Transportation</h5>


                                        <p className="w-85 md-w-100">Toll roads and airports are one of the group's
                                            specialties.</p>
                                    </div>
                                    {/* end counter item */}
                                    {/* start counter item */}
                                    <div className="col counter-style-01 last-paragraph-no-margin">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Energy and Oil & Gas</h5>

                                        <p className="w-85 md-w-100">Power generation, transmission, and distribution
                                            projects, as well as fossil energy projects.</p>
                                    </div>
                                    {/* end counter item */}
                                </div>
                            </div>
                            <div className="col-12" id="tab-sectores">
                                <div
                                    className="w-100 h-1px bg-medium-gray margin-7-rem-top margin-8-rem-bottom sm-margin-5-rem-tb"/>
                            </div>
                        </div>
                        <div className="row tab-sectores">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                {/* start tab navigation */}
                                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom">
                                    <li className="transportacion nav-item"><a
                                        className="transportacion nav-link active" data-toggle="tab"
                                        href="#planning-tab">TRANSPORTATION</a></li>
                                    <li className="nav-item energia"><a className="energia nav-link" data-toggle="tab"
                                                                        href="#research-tab">ENERGY</a></li>
                                    <li className="nav-item telecomunicaciones"><a
                                        className="telecomunicaciones nav-link" data-toggle="tab"
                                        href="#telecomunicaciones-tab">TELECOMMUNICATIONS</a>
                                    </li>
                                    <li className="nav-item infraestructura"><a className="infraestructura nav-link"
                                                                                data-toggle="tab"
                                                                                href="#infraestructura-tab">SOCIAL
                                        INFRASTRUCTURE</a>
                                    </li>
                                    <li className="nav-item finanzas"><a className="finanzas nav-link" data-toggle="tab"
                                                                         href="#target-tab">PUBLIC FINANCE</a></li>
                                    <li className="nav-item sector"><a className="sector nav-link" data-toggle="tab"
                                                                       href="#inmobiliario-tab">REAL ESTATE SECTOR</a>
                                    </li>

                                </ul>
                                {/* end tab navigation */}
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="planning-tab"
                                         className="transportacion_content tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={transporte} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Transportation</h5>
                                                <p className="w-85 lg-w-100">We have advised on transactions and
                                                    financing of:
                                                </p>
                                                <ul className="list3">
                                                    <li>Highways</li>
                                                    <li>Airports</li>
                                                    <li>Trains</li>
                                                    <li>Bridges</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="research-tab" className="energia_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/interior/energia.jpg"} className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Energy and Oil & Gas</h5>
                                                <p className="w-85 lg-w-100">We have advised on transactions and
                                                    financings, as well as evaluated investments in electric power and
                                                    fossil fuels:
                                                </p>
                                                <ul className="list3">
                                                    <li>Power generation (DC, mechanical, solar, wind)</li>
                                                    <li>Transmission lines</li>
                                                    <li>Qualified supply</li>
                                                    <li>Developers and energy companies in general</li>
                                                    <li>Storage facilities</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="telecomunicaciones-tab"
                                         className="telecomunicaciones_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={telecomunicaciones} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Telecommunications</h5>
                                                <p className="w-85 lg-w-100">We are experienced in advising on financing
                                                    solutions to grow telecom businesses, mainly related to fiber
                                                    optics, among others.
                                                </p>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="infraestructura-tab" className="infraestructura_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={social} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Social infrastructure</h5>
                                                <p className="w-85 lg-w-100">We advise on financing for:</p>
                                                <ul className="list3">
                                                    <li>Public Property Registry</li>
                                                    <li>Prisons</li>
                                                    <li>Hospitals</li>
                                                    <li>Educational facilities</li>
                                                    <li>Water</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="target-tab" className="finanzas_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={finanzas} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Public Finance</h5>
                                                <p className="w-85 lg-w-100">We advise governments and governmental
                                                    entities on:
                                                </p>
                                                <ul className="list3">
                                                    <li>Public debt financings and restructurings</li>
                                                    <li>Securitization of local duties and taxes</li>
                                                    <li>PPP design and bidding</li>
                                                    <li>Advice on public finance management, credit rating, and
                                                        financing of strategic projects
                                                    </li>
                                                </ul>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="inmobiliario-tab" className="sector_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={inmobiliario} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Real estate sector</h5>
                                                <p className="w-85 lg-w-100">We advise and engage in the development of
                                                    strategic real estate, including new buildings and refurbishment of
                                                    corporate offices to residential and mixed-use spaces for sale
                                                    and/or lease.
                                                </p>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}
            </>
        </Layout>
    )
}

export default SectoresPage
