import React, {useContext, useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import "../../../static/css/font-icons.min.css"
import "../../../static/css/theme-vendors.min.css"
import "../../../static/css/style.css"
import "../../../static/css/hide_recaptcha.css"
import "../../../static/css/responsive.css"
import "../../../static/css/david.css"
import "../../../static/css/antonio.css"
import "../../../static/css/tania.css"

import Header from "./header-int"
import Footer from "../footer"
import {LangProvider} from "../../context/LangContext";
import preloader from "../../../static/images/latam_black-grueso.svg";
import LangContext from "../../context/LangContext";

const jQuery = require("jquery");
const Layout = ({children, title, location}) => {
    const {lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            // window.funciones()
            jQuery('.dropdown').on('mouseenter', function (e) {
                var _this = jQuery(this);
                _this.addClass('open');
                _this.siblings('.dropdown').removeClass('open');
            }).on('mouseleave', function (e) {
                var _this = jQuery(this);
                _this.removeClass('menu-left');
                _this.removeClass('open');
            });
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <>
            <LangProvider>
                <Header location={location}/>
                <div className="preloader text-center">
                    <img src={preloader} alt=""/>
                </div>
                <main>{children}</main>
                <section className="cover-background wow animate__fadeIn"
                         style={{backgroundImage: 'url("/images/lca/interior/foot3b.jpg")'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-7 wow animate__fadeIn">
                                <div className="margin-25px-bottom text-white opacity-6 alt-font">
                                    {data["como_te_podemos_ayudar"]}
                                </div>
                                <h3 className="alt-font text-white font-weight-500 margin-40px-bottom">
                                    {data["text_help"]}</h3>
                                <a href="mailto:info@latamcapitaladvisors.com"
                                   className="btn btn-large btn-expand-ltr text-white lg-margin-15px-bottom md-no-margin-bottom md-margin-auto-lr">info@latamcapitaladvisors.com<span
                                    className="bg-transparent-black"></span></a>
                            </div>
                        </div>
                    </div>
                </section>
                <a aria-label="scroll-top" className="scroll-top-arrow" href="#top"><i
                    className="feather icon-feather-arrow-up"></i></a>
                <Footer/>
            </LangProvider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
